import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './blog.module.css'

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      frontmatter {
        date
        path
        author
        authorUrl
        title
      }
    }
  }
`

export default function Template({data}) {
  const {markdownRemark} = data
  const {frontmatter, html} = markdownRemark
  const {title, date, author, authorUrl} = frontmatter
  const isUpdate =
    title.split(' ').length === 3 &&
    title.split(' ')[1].length === 4 &&
    title.split(' ')[2].toLowerCase() === 'update'
  return (
    <Layout>
      <SEO title={title} />
      <div className={styles.blogContainer}>
        <h1>{title}</h1>
        <span className={styles.metadata}>{date}</span>
        {author && authorUrl ? (
          <>
            <br />
            <a href={authorUrl}>
              <span className={styles.metadata}>{author}</span>
            </a>
          </>
        ) : author ? (
          <>
            <br />
            <span className={styles.metadata}>{author}</span>
          </>
        ) : null}
        <div
          className={styles.blogContent}
          dangerouslySetInnerHTML={{__html: html}}
        />
        {isUpdate ? (
          <div className={styles.joinNewsletter}>
            Receive this monthly newsletter by email on the 5th day of the every
            month, by donating to our Open Collective page.
            <br />
            <br />
            <Link to={'/donate'}>Become a backer!</Link>
          </div>
        ) : null}
      </div>
    </Layout>
  )
}
